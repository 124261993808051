import { useTranslations } from "@app-i18n/useTranslations";
import { useGlobalStore } from "@app-shell-store/global";
import { useUserStore } from "@app-shell-store/user";
import usePersistentStorage from "@app-utilities/persistent-storage";
import AccessDenied from "@app-views/access-denied/access-denied.vue";
import Home from "@app-views/home/home.vue";
import InstallPwa from "@app-views/install-pwa/install-pwa.vue";
import Login from "@app-views/login/login.vue";
import LoginCallback from "@app-views/login/login-callback.vue";
import LogoutCallback from "@app-views/login/logout-callback.vue";
import NotFound from "@app-views/not-found/not-found.vue";
import TerminalPairing from "@app-views/pairing/terminal-pairing.vue";
import { connect, getNativeWebView } from "@qamf/conqueror-native";
import { useAuthentication, useObservability } from "@qamf/shell-app-sdk";
import { createRouter, createWebHistory, type RouteRecordRaw } from "vue-router";

import { modules } from "../boot";
import { appSettingsReady } from "../main";

type AccessHandler = {(): boolean};

export type AppRouteMeta = {
	allowAnonymous?: boolean
	allowUnpaired?: boolean
	allowBrowser?: boolean
	excludeNavBarItem?: AccessHandler
	showIf?: AccessHandler
	accessIf?: AccessHandler
	onBeforeEachModuleRoute?: (() => void)
}

export type AppRoute = RouteRecordRaw & {
	meta: AppRouteMeta
}
const routes: Array<AppRoute> = [
	{
		path: "/",
		name: "home",
		component: Home,
		meta: {
			excludeNavBarItem: () => true
		}
	},
	{
		path: "/install",
		name: "install",
		component: InstallPwa,
		meta: {
			allowAnonymous: true,
			allowUnpaired: true,
			allowBrowser: true,
			excludeNavBarItem: () => true
		}
	},
	{
		path: "/pairing",
		name: "pairing",
		component: TerminalPairing,
		meta: {
			allowAnonymous: true,
			allowUnpaired: true,
			excludeNavBarItem: () => true
		}
	},
	{
		path: "/signin-oidc",
		name: "login-callback",
		component: LoginCallback,
		meta: {
			allowAnonymous: true,
			excludeNavBarItem: () => true
		}
	},
	{
		path: "/login",
		name: "login",
		component: Login,
		meta: {
			allowAnonymous: true,
			excludeNavBarItem: () => true
		}
	},
	{
		path: "/logout",
		name: "logout-callback",
		component: LogoutCallback,
		meta: {
			allowAnonymous: true,
			excludeNavBarItem: () => true
		}
	},
	{
		path: "/access-denied",
		name: "access-denied",
		component: AccessDenied,
		meta: {
			allowAnonymous: true,
			excludeNavBarItem: () => true
		}
	},
	{
		path: "/not-found",
		name: "not-found",
		component: NotFound,
		meta: {
			allowAnonymous: true,
			allowUnpaired: true,
			allowBrowser: true,
			excludeNavBarItem: () => true
		}
	}
];

const router = createRouter({
	history: createWebHistory("/"),
	linkActiveClass: "active",
	routes,
	scrollBehavior(to) {
		if (to.hash) {
			return {
				selector: to.hash,
				behavior: "smooth"
			};
		}
		return { x: 0, y: 0 };
	}
});

router.beforeEach(async(to, from, next) => {
	if (!to.name && !to.path) return;

	const { isAuthenticated } = useAuthentication();
	const { getItem } = usePersistentStorage();
	const { ensureUserFunctionalities } = useUserStore();
	const globalStore = useGlobalStore();
	const isNative = Boolean(getNativeWebView());
	const isUserAuthenticated = isNative || await isAuthenticated();
	const isTerminalPaired = isNative || getItem("terminalPaired");
	const isStandalone = isNative || globalStore.isStandalone;
	const isBrowserPreferred = isNative || globalStore.isBrowserPreferred || getItem("browserPreferred");
	const isUserLogged = await isAuthenticated();

	if (!to.meta.allowBrowser && !isStandalone && !isBrowserPreferred)
		return next({ name: "install" });

	if (!to.meta.allowUnpaired && !isTerminalPaired)
		return next({ name: "pairing" });

	if (!to.meta.allowAnonymous && !isUserAuthenticated)
		return next({ name: "login" });

	if (isTerminalPaired && to.name === "pairing")
		return next({ name: "home" });

	if (isTerminalPaired && isUserLogged)
		await ensureUserFunctionalities();

	const routeToName = to.name?.toString() ?? "";
	if (routeToName && routeToName.toString().startsWith("module-")) {
		const { translateKey } = useTranslations();
		connect().moduleInteraction.setModuleTitle(translateKey(`${routeToName}_title`));
	}

	if (routeToName && appSettingsReady.value) {
		const { trackView } = useObservability();
		trackView(routeToName, "start", to.fullPath);
	}

	const routeNames = router.options.routes.map(route => route.name);
	const moduleIds = modules.map(module => module.id);
	const isKnownRoute = routeNames.includes(routeToName);
	const isModuleRoute = routeToName.startsWith("module-") || moduleIds.map(mp => to.path.startsWith(`/${mp}/`)).reduce((a, b) => a || b, false);

	if (!isKnownRoute && !isModuleRoute)
		return next({ name: "not-found" });

	if (typeof to.meta.showIf === "function") {
		const canShow = to.meta.showIf();
		if (!canShow)
			return next({ name: "access-denied" });
	}

	if (typeof to.meta.onBeforeEachModuleRoute === "function")
		await to.meta.onBeforeEachModuleRoute();

	next();
});

router.afterEach(to => {
	if (!to.name && !to.path) return;

	const routeToName = to.name?.toString() ?? "";
	if (routeToName && appSettingsReady.value) {
		const { trackView } = useObservability();
		trackView(routeToName, "end", to.fullPath);
	}
});

export default router;
