import { useAppRestClient } from "@app-shell-rest/configurations";
import { showModalAccessDenied } from "@app-utilities/modals";
import type { PortalFunctionalities } from "@app-utilities/security";
import { useAppStore, useAuthentication, useCloudBackend, useObservabilityManager, useSecurityManager } from "@qamf/shell-app-sdk";
import type { UserInfo } from "@qamf/shell-app-sdk/interfaces/authentication";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

let ensurePromise: null | Promise<void> = null;

export const useUserStore = defineStore("user", () => {
	const userLogged = ref(false);
	const syncedFunctionalities = ref(false);
	const functionalities = ref<PortalFunctionalities | null>(null);

	const userInfo = ref<UserInfo | undefined>();
	const userIsAuthenticated = computed(() => userLogged.value);
	const userDetails = computed(() => userInfo.value);
	const userFunctionalities = computed(() => functionalities.value);

	async function syncUserState() {
		const { isAuthenticated, getTokens } = useAuthentication();
		const { getOption, setOption } = useCloudBackend();
		const { setAuthenticatedUserContext, clearAuthenticatedUserContext } = useObservabilityManager();
		const isUserLogged = await isAuthenticated();

		if (isUserLogged) {
			const userTokens = await getTokens();
			const accessToken = userTokens?.accessToken;
			if (accessToken) {
				const headers = getOption("headers");
				headers.set("Authorization", `Bearer ${accessToken}`);
				setOption("headers", headers);
			}

			if (userInfo.value)
				setAuthenticatedUserContext(userInfo.value.email);
		} else
			clearAuthenticatedUserContext();

		userLogged.value = isUserLogged;
	}

	async function syncUserInfo() {
		const { getUser } = useAuthentication();
		const userResponse = await getUser();
		if (!userResponse) return;
		userInfo.value = userResponse;
	}

	async function syncUserFunctionalities() {
		const { setupSecurity } = useSecurityManager();
		const { systemId } = useAppStore();

		if (!systemId.value)
			throw new Error("Get functionalities failed. System ID not found.");

		const { getFunctionalities } = useAppRestClient();
		const functionalitiesResponse = await getFunctionalities(systemId.value);
		if (!functionalitiesResponse.fetchResponse?.ok || !functionalitiesResponse.data)
			return new Error("Get functionalities failed");

		if (Object.keys(functionalitiesResponse.data).length === 0)
			return new Error("Empty functionalities");

		setupSecurity(functionalitiesResponse.data);
		functionalities.value = functionalitiesResponse.data;
		syncedFunctionalities.value = true;
	}

	async function ensureUserFunctionalities() {
		if (!ensurePromise) {
			ensurePromise = new Promise<void>(async(resolve, reject) => {
				if (syncedFunctionalities.value) return resolve();

				const response = await syncUserFunctionalities();
				if (response instanceof Error) {
					showModalAccessDenied();
					reject(Error);
				}
				resolve();
			});
		}
		await ensurePromise;
		ensurePromise = null;
	}

	return {
		userIsAuthenticated,
		userDetails,
		userFunctionalities,
		syncUserState,
		syncUserInfo,
		syncUserFunctionalities,
		ensureUserFunctionalities
	};
});
