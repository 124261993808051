<template>
	<div>
		<QOverlay
			:show="isAppLoading"
			class="layout-wrapper"
			:class="{
				'layout-native' : isNative
			}"
		>
			<template #overlay>
				<QSpinner
					inline
					size="md"
				/>
			</template>
			<QButton
				v-if="showModalSettingDuringPairing"
				variant="outline-secondary"
				class="btn-modal-settings position-absolute top-0 end-0 m-5"
				@click="showSettings = true"
			>
				<i class="bi bi-gear" />
			</QButton>
			<QNavbar
				v-if="isTerminalPaired && isUserAuthenticated"
				title=""
				:nav-items="modulesNavBarItems"
				container="fluid"
				:hide-toggler="false"
				:hide-nav="isSmall"
			>
				<template
					v-if="isBrowser"
					#brand-image
				>
					<img
						src="/assets/QubicaAMF_logo.png"
						alt=""
						width="50"
					>
				</template>
				<template #start>
					<div />
				</template>
				<template #end>
					<div class="d-flex align-items-center justify-content-center position-relative">
						<QPopover
							v-if="isClientOffline || isCloudOffline"
							:show="showOfflineTooltip"
						>
							<div class="d-flex align-items-center mb-3">
								<QButton
									pill
									@click="reloadLocationClick"
								>
									<i class="bi bi-arrow-clockwise" />
								</QButton>
								<span class="title ms-2">{{ offlineErrorTitle }}</span>
							</div>
							{{ offlineErrorMessage }}
						</QPopover>
						<div
							class="d-flex align-items-center me-3"
							:class="{
								'text-success' : !isClientOffline && !isCloudOffline,
								'text-danger': isClientOffline || isCloudOffline
							}"
						>
							<i class="bi bi-globe2 fs-5 px-1" />
						</div>
						<QPopover
							v-if="isUserAuthenticated"
							:show="showUserActions"
						>
							<a
								class="action"
								@click.prevent="onLogoutClick"
							>
								<i class="bi bi-box-arrow-right me-2" /> {{ translateKey("logout") }}
							</a>
						</QPopover>
						<div class="d-flex align-items-center">
							<i class="bi bi-person-circle fs-5" />
							<span class="d-none d-sm-inline-block ps-2">{{ translateKey("hi_user", { userName }) }}</span>
						</div>
						<template v-if="isDevelopmentOrTestingSlot">
							<QButton
								variant="outline-secondary"
								class="ms-3"
								@click="showSettings = true"
							>
								<i class="bi bi-gear" />
							</QButton>
						</template>
					</div>
				</template>
			</QNavbar>

			<div :class="mainContainerClasses">
				<router-view />
			</div>

			<QSettings
				v-if="isDevelopmentOrTestingSlot"
				v-model="showSettings"
			/>
		</QOverlay>
		<QModal
			v-model="inactivityModal"
			:title="translateKey(`inactivity_modal_title`)"
			:ok-title="translateKey(`yes`)"
			size="sm"
			hide-header-close
			no-close-on-backdrop
			no-close-on-esc
			@ok="onKeepLoggedClick"
		>
			{{ translateKey(`inactivity_modal_text`) }}
			<template #cancel>
				<QButton
					variant="outline-secondary"
					@click="onLogoutClick"
				>
					{{ translateKey(`logout_countdown_param`, { countdown: modalLogoutCountDown }) }}
				</QButton>
			</template>
		</QModal>
		<QToastOrchestrator />
		<QModalOrchestrator />
	</div>
</template>
<script lang="ts" src="./app.ts"></script>
