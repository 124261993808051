import { initMultiLanguage, useTranslations } from "@app-i18n/useTranslations";
import { useAppRestClient } from "@app-shell-rest/configurations";
import { useGlobalStore } from "@app-shell-store/global";
import { useLangStore } from "@app-shell-store/lang";
import { useUserStore } from "@app-shell-store/user";
import useEventsManager from "@app-utilities/events-manager";
import { showModalError } from "@app-utilities/modals";
import { AppInsightsEvent } from "@app-utilities/observability-events";
import usePersistentStorage from "@app-utilities/persistent-storage";
import { connect, getNativeWebView } from "@qamf/conqueror-native";
import { defineImplementation, getModuleInstance, type ModuleDefinition, registerModule, use4DAgent, useAppSettings, useAppSettingsManager, useAppStore, useAppStoreManager, useEndpointsStoreManager, useObservability, useObservabilityManager, useSecurityManager } from "@qamf/shell-app-sdk";
import { useDebounceFn } from "@vueuse/core";
import type { RestError } from "scarlett";
import { h, ref } from "vue";
import type { RouteRecordRaw } from "vue-router";

import define4DAgent from "./defined-implementations/define-4DAgent";
import defineAuthentication from "./defined-implementations/define-authentication";
import defineCloudBackend from "./defined-implementations/define-cloud-backend";
import defineLocalBackend from "./defined-implementations/define-local-backend";
import QDeskApp from "./main";
import registerServiceWorker from "./registerServiceWorker";
import router, { type AppRoute } from "./router";

type ModuleId = "lane-options" | "neoverse";
type Module = {
	id: ModuleId;
	title: string;
	path: `/${string}`,
	type: "custom-element" | "vue-component";
}
export const modules: Module[] = [
	{
		id: "lane-options",
		title: "Lane Options",
		path: "/lane-options",
		type: "custom-element"
	},
	{
		id: "neoverse",
		title: "Neoverse",
		path: "/neoverse",
		type: "vue-component"
	}
];

async function resolveAppContext() {
	const { setupAppSettings } = useAppSettingsManager();

	await setupAppSettings({
		launchModeResolver() {
			return Promise.resolve(__LAUNCH_MODE__);
		},
		versionResolver() {
			return Promise.resolve(__VERSION__);
		},
		async settingsResolver() {
			const contextFileUrl = __LAUNCH_MODE__ === "Standard"
				? "/context.json"
				: `/settings/${__ENVIRONMENT__}-${__CHANNEL__}.json`;
			const response = await fetch(contextFileUrl, { method: "GET", cache: "no-cache" })
				.then((data) => data.json())
				.catch((error) => {
					console.error(error);
					showModalError("Error", "File not found");
				});

			return response;
		}
	});
}

async function setupObservability() {
	const { setup, setCommonPropsResolver } = useObservabilityManager();
	const { appInsightsConnString } = useAppSettings();
	const isNative = Boolean(getNativeWebView());

	if (!appInsightsConnString.value) throw new Error("[APP INSIGHTS] Missing App Insight connection string.");

	setup({
		config: {
			connectionString: appInsightsConnString.value,
			maxAjaxCallsPerView: -1,
			disableExceptionTracking: true,
			disableTelemetry: __LAUNCH_MODE__ !== "Standard"
		}
	});
	setCommonPropsResolver(function commonObservabilityProps() {
		const route = router.currentRoute;
		const { userDetails, userFunctionalities } = useUserStore();
		const { systemId } = useAppStore();
		const { launchMode, channel, environment, version, cloudBackendUrl } = useAppSettings();
		const { isStandalone } = useGlobalStore();
		const { getItem } = usePersistentStorage();
		const terminalNumber = getItem("terminalNumber");
		const mode = isNative ? "Native" : isStandalone ? "Standalone" : "Browser";

		const commonProperties = {
			routePath: route.value?.fullPath ?? "",
			routeName: route.value?.name ?? "",
			launchMode: launchMode.value,
			channel: channel.value,
			environment: environment.value,
			version: version.value,
			mode,
			cloudBackendUrl: cloudBackendUrl.value,
			user: userDetails,
			functionalities: userFunctionalities,
			systemId: systemId.value,
			terminalNumber
		};
		return commonProperties;
	});
}

function initErrorHandling() {
	const fatalErrors: any[] = [];
	const logErrors = useDebounceFn(() => {
		const printErrorEl = (el: any, title?: string) => {
			console.groupCollapsed("\x1b[31m%s\x1b[0m", title ?? el.error.message);
			console.info(el.datetime);
			console.error(el.error);
			if (el.extras)
				console.info(el.extras);
			console.groupEnd();
		};
		if (fatalErrors.length === 1)
			printErrorEl(fatalErrors[0], "A fatal error occurred");

		else {
			console.groupCollapsed("\x1b[31m%s\x1b[0m", `Intercepted fatal errors: ${fatalErrors.length}`);
			fatalErrors.forEach(er => printErrorEl(er));
			console.groupEnd();
		}
	}, 1000);
	const { trackException, trackEvent } = useObservability();
	const { onError, emitError } = useEventsManager();
	const { setIsCloudOffline } = useGlobalStore();

	onError(async(error, extras) => {
		const tracked = await trackException(error, extras);
		fatalErrors.push({ error, extras, datetime: new Date() });
		logErrors();
		trackEvent(AppInsightsEvent.FatalError, {
			description: "Unhandled exception feedback to user",
			error: tracked?.exception,
			errorName: tracked?.exception.name,
			errorStack: tracked?.exception.stack,
			errorMessage: tracked?.exception.message,
			errorExtras: tracked?.properties
		}, true);

		const statusCode = (tracked?.exception as RestError)?.statusCode;
		const isBadGateway = statusCode === 502;
		const isServiceUnavailable = statusCode === 503;
		const isGatewayTimeout = statusCode === 504;
		const cloudIsDown = isBadGateway || isServiceUnavailable || isGatewayTimeout;
		setIsCloudOffline(cloudIsDown);

		if (!cloudIsDown)
			showModalError("Fatal Error", `Unhandled exception: ${tracked?.exception.message}`);
	});
	window.onerror = (message, source, lineno, colno, error) => {
		if (!error) return;

		emitError(error, { message, source, lineno, colno });
		return true;
	};
	window.onunhandledrejection = event => {
		event.preventDefault();
		if (event.reason instanceof Error) emitError(event.reason);
		else emitError(new Error("Promise Rejection"), event.reason);
	};
	QDeskApp.config.errorHandler = (err: any, instance: any, info: any) => {
		emitError(err as Error, { info });
	};
}

export const routesRegistered = ref<RouteRecordRaw[]>([]);

async function checkTerminalPairing() {
	const { getItem, clear } = usePersistentStorage();
	const systemId = getItem("systemId");
	if (!systemId) {
		console.warn("Pairing failed. System ID not found.");
		return;
	}

	const terminalNumber = getItem("terminalNumber");
	if (!terminalNumber) {
		console.warn("Pairing failed. Terminal number not found.");
		return;
	}

	const { getCenterInfo } = useAppRestClient();
	const centerResponse = await getCenterInfo(systemId, terminalNumber);
	if (!centerResponse.fetchResponse?.ok || !centerResponse.data) {
		clear();
		return;
	}

	const { setSystemId } = useAppStoreManager();
	const { setLanguage, getIsoCode } = useTranslations();
	const { setupI18nApp } = useLangStore();

	setSystemId(systemId);

	const companyLanguage = centerResponse.data.CompanyLanguage ?? "en-US";
	await setLanguage(companyLanguage);
	await setupI18nApp(getIsoCode());
}

const getNeoverseConfig = async() => {
	const { systemId } = useAppStore();
	const { setOption } = use4DAgent();
	const { getNeoverseCloudConfiguration } = useAppRestClient();
	const { setItem } = usePersistentStorage();

	if (!systemId.value)
		throw new Error("Missing SystemId on getNeoverseConf");

	const response = await getNeoverseCloudConfiguration(systemId.value);
	if (!response.fetchResponse?.ok || !response.data)
		return console.warn(response.error?.code);

	setOption("host", `https://${response.data.LanIpAddress}:5253`);
	setItem("terminalAuthInfo", {
		LanIpAddress: response.data.LanIpAddress
	});
};

const beforeModuleRoutes: Partial<Record<ModuleId, ()=> void>> = {
	neoverse: async() => {
		await getNeoverseConfig();
	}
};

function unwrapModuleDefinitionExport(module: ModuleDefinition | { default: ModuleDefinition }) {
	return "default" in module ? module.default : module;
}

async function registerModules() {
	const isNative = Boolean(getNativeWebView());
	const { trackEvent } = useObservability();

	for (const module of modules) {
		trackEvent(AppInsightsEvent.ModuleRequest, { moduleId: module.id });

		const moduleImport = import(`@qamf/module-${module.id}/dist/index`).then(unwrapModuleDefinitionExport);
		const cssImport = module.type === "vue-component" ? import(`@qamf/module-${module.id}/dist/module.css`) : Promise.resolve();
		const [mod] = await Promise.all([moduleImport, cssImport]);

		trackEvent(AppInsightsEvent.ModuleDownloaded, { moduleId: module.id });

		trackEvent(AppInsightsEvent.ModuleSetupStart, { moduleId: module.id });
		await registerModule({
			appName: "QDesk",
			appVersion: __VERSION__,
			basePath: module.path
		}, mod);

		const component = getModuleInstance(mod.id)?.component;
		if (!component) throw new Error(`Module with id ${mod.id} was not registered`);

		const routeModule: AppRoute = {
			path: `${module.path}/:pathMatch(.*)*`,
			name: `module-${module.id}`,
			meta: {
				title: module.title,
				showIf: module.id === "lane-options" ? () => isNative : undefined,
				onBeforeEachModuleRoute: beforeModuleRoutes[module.id]
			},
			component: module.type === "custom-element" ? h(`qamf-module-${module.id}`) : component
		};
		router.addRoute(routeModule);

		trackEvent(AppInsightsEvent.ModuleSetupEnd, { moduleId: module.id });

		routesRegistered.value.push(routeModule);
	}
}

export async function initLocalFunctionalities() {
	const { getFunctionalitiesLocal } = useAppRestClient();
	const functionalities = await getFunctionalitiesLocal();

	if (!functionalities.fetchResponse?.ok || !functionalities.data)
		return new Error("Get local functionalities failed");

	const { setupSecurity } = useSecurityManager();
	setupSecurity(functionalities.data);
}

export default async function boot() {
	const { initPwaEvents, isBrowser } = useGlobalStore();

	initPwaEvents();
	await resolveAppContext();

	await setupObservability();
	initErrorHandling();
	await initMultiLanguage();
	registerServiceWorker();

	defineImplementation("useCloudBackend", defineCloudBackend());
	defineImplementation("useLocalBackend", defineLocalBackend());
	defineImplementation("use4DAgent", define4DAgent());
	defineImplementation("useAuthentication", defineAuthentication);

	if (!isBrowser) {
		const conqueror = connect();
		const [centerId, getLocalBackendBaseUrl] = await Promise.all([
			conqueror.settings.getCenterId(),
			conqueror.settings.getLocalBackendBaseUrl()
		]);
		const { setSystemId } = useAppStoreManager();
		setSystemId(Number(centerId));
		const { setLocalBackend } = useEndpointsStoreManager();
		setLocalBackend(getLocalBackendBaseUrl);
		defineImplementation("useLocalBackend", defineLocalBackend()); // FIXME: #72242 remove double defineImplementation
		await initLocalFunctionalities();
	}

	await registerModules();

	await checkTerminalPairing();
}
