/* eslint-disable camelcase */
import { useUserStore } from "@app-shell-store/user";
import usePersistentStorage from "@app-utilities/persistent-storage";
import useTokenUtilities from "@app-utilities/token-utilities";
import { useAppSettings, useCloudBackend } from "@qamf/shell-app-sdk";
import type { UserInfo, UserToken } from "@qamf/shell-app-sdk/interfaces/authentication";
import { UserManager, type UserManagerSettings } from "oidc-client-ts";

let userManager: UserManager | null = null;

export default function defineAuthentication() {
	function getAuthorityUrl() {
		const { authSettings, environment, channel } = useAppSettings();
		const { getOption } = useCloudBackend();

		if (!authSettings || !authSettings.value?.authority || !environment.value)
			throw new Error("[AUTHENTICATION getAuthorityUrl] Missing settings.");

		let authority = authSettings.value.authority;

		const isDevelopSession = ["development", "testing"].includes(environment.value) && channel.value === "beta";
		if (isDevelopSession) {
			const cloudBackendUrl = getOption("host");
			authority = `${cloudBackendUrl}/identityprovider`;
		}

		return authority;
	}

	function getClient(): UserManager {
		if (userManager) return userManager;

		const { authSettings } = useAppSettings();

		const currentHost = `${window.location.protocol}//${window.location.host}`;
		const authority = getAuthorityUrl();

		if (!authority || !authSettings.value?.clientId || !authSettings.value.responseType || !authSettings.value.scopes)
			throw new Error("[AUTHENTICATION getClient] Missing settings.");

		const config: UserManagerSettings = {
			authority,
			client_id: authSettings.value.clientId,
			redirect_uri: `${currentHost}/signin-oidc`,
			response_type: authSettings.value.responseType,
			scope: authSettings.value.scopes
		};
		userManager = new UserManager(config);
		userManager.events.addUserSignedIn(function() {
			useUserStore().syncUserState();
		});
		userManager.events.addUserLoaded(function() {
			useUserStore().syncUserState();
		});

		return userManager;
	}

	async function getUser(): Promise<UserInfo | undefined> {
		const userManager = getClient();
		const user = await userManager.getUser();
		if (!user)
			return;

		const userInfo: UserInfo = {
			firstName: user.profile.given_name || "",
			lastName: user.profile.family_name || "",
			email: user.profile.email || ""
		};
		return userInfo;
	}

	async function getTokens(): Promise<UserToken | undefined> {
		const userManager = getClient();
		const user = await userManager.getUser();
		if (!user)
			return;

		const userTokens: UserToken = {
			accessToken: user.access_token,
			accessTokenExpireIn: user.expires_in,
			identityToken: user.id_token,
			refreshToken: user.refresh_token
		};
		return userTokens;
	}

	async function login(): Promise<void> {
		try {
			const { getItem } = usePersistentStorage();
			const terminalToken = getItem("terminalToken");
			if (!terminalToken) {
				console.warn("Failed process login: missing terminalToken.");
				return;
			}

			const { parseTokenJWT } = useTokenUtilities();
			const { oi_tkn_id } = parseTokenJWT(terminalToken.access_token);
			const extraQueryParams = { oi_tkn_id };

			const userManager = getClient();
			await userManager.signinRedirect({ extraQueryParams });
		} catch (error) {
			throw new Error(`Error during login: ${error}`);
		}
	}

	async function logout(): Promise<void> {
		try {
			const currentHost = `${window.location.protocol}//${window.location.host}`;

			const userManager = getClient();
			const tokens = await getTokens();
			await userManager.signoutRedirect({
				id_token_hint: tokens?.identityToken,
				post_logout_redirect_uri: `${currentHost}/logout`
			});
		} catch (error) {
			throw new Error(`Error during logout: ${error}`);
		}
	}

	async function loginProcessCallback(): Promise<boolean | Error> {
		try {
			const urlParams = new URLSearchParams(window.location.search);
			const error = urlParams.get("error");
			if (error === "access_denied") {
				// FIXME: Needs to be fixed on BE first
				// Bug 54965: [QDesk] Infinite loop if logged on ADFS with access_denied
				return new Error("Failed process login: access denied");
			}

			const userManager = getClient();
			await userManager.signinCallback();
			return true;
		} catch (error) {
			return new Error(`Error during login callback: ${error}`);
		}
	}

	async function logoutProcessCallback(): Promise<true | Error> {
		try {
			const userManager = getClient();
			await Promise.all([
				userManager.signoutCallback(),
				userManager.removeUser()
			]);
			return true;
		} catch (error) {
			return new Error(`Error during logout callback: ${error}`);
		}
	}

	async function isAuthenticated() {
		const userManager = getClient();
		const user = await userManager.getUser();
		return !!user && !user.expired;
	}

	async function regenerateTokens(): Promise<true | Error> {
		// FIXME: Implement method when handling silentRenew properly
		// Tech Story 54475: [QDesk] Implement manual silentRenew
		return true;
	}

	return {
		login,
		logout,
		loginProcessCallback,
		logoutProcessCallback,
		isAuthenticated,
		getUser,
		getTokens,
		regenerateTokens
	};
}
