import { useUserStore } from "@app-shell-store/user";
import { QOverlay } from "@qamf/lighthouse";
import { useAuthentication } from "@qamf/shell-app-sdk";
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
	name: "LoginCallback",
	components: {
		QOverlay
	},
	setup() {
		const { loginProcessCallback } = useAuthentication();
		const router = useRouter();
		const userStore = useUserStore();
		const isViewLoading = ref(false);

		const triggerLocalStorageUpdate = () => {
			window.dispatchEvent(new Event("storage"));
		};

		onMounted(async() => {
			isViewLoading.value = true;
			const navigationStatus = await loginProcessCallback();

			await Promise.all([
				userStore.syncUserState(),
				userStore.syncUserInfo()
			]);

			if (navigationStatus === true) {
				triggerLocalStorageUpdate();
				return router.push({ name: "home" });
			} else {
				isViewLoading.value = false;
				throw navigationStatus;
			}
		});

		return {
			isViewLoading
		};
	}
});
