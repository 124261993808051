import QSettings from "@app-components/q-settings/q-settings.vue";
import router from "@app-router/index";
import { AppInsightsEvent } from "@app-utilities/observability-events";
import { createDsy, QCol, QRow } from "@qamf/lighthouse";
import { useObservability } from "@qamf/shell-app-sdk";
import { createPinia } from "pinia";
import { createApp, ref } from "vue";

import MainApp from "./app.vue";
import boot from "./boot";

const pinia = createPinia();

const QDeskApp = createApp(MainApp);

export const appSettingsReady = ref(false);

window.addEventListener("load", async() => {
	QDeskApp.component("QSettings", QSettings);
	QDeskApp.component("QCol", QCol);
	QDeskApp.component("QRow", QRow);

	QDeskApp.use(pinia);
	// Boot needs pinia to be initialized
	await boot();
	QDeskApp.use(createDsy({
		modalManager: true,
		modalController: true,
		toast: true
	}));
	QDeskApp.use(router);
	appSettingsReady.value = true;

	const { trackEvent } = useObservability();
	trackEvent(AppInsightsEvent.BootCompleted);
	QDeskApp.mount("#app");
});

export default QDeskApp;
