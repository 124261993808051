import router from "@app-router/index";
import { AppInsightsEvent } from "@app-utilities/observability-events";
import { getNativeWebView } from "@qamf/conqueror-native";
import { useObservability } from "@qamf/shell-app-sdk";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useGlobalStore = defineStore("global", () => {
	const isClientOffline = ref(false);
	const isCloudOffline = ref(false);
	const isBrowserPreferredByUser = ref(false);
	const isBrowserPreferred = computed(() => isBrowserPreferredByUser.value);
	const isBrowser = computed(() => !getNativeWebView());
	const isStandalone = computed(() => Boolean(window.matchMedia("(display-mode: standalone)").matches || ("standalone" in navigator && (navigator as any).standalone === true)));
	const isSafariDesktop = computed(() => {
		return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	});
	const isSafariMobile = computed(() => {
		return /iPhone|iPad|iPod/i.test(navigator.userAgent);
	});
	const deferredPrompt = ref<any>(null);

	function setIsClientOffline(value: boolean) {
		isClientOffline.value = value;
	}

	function setIsCloudOffline(value: boolean) {
		isCloudOffline.value = value;
	}

	function setIsBrowserPreferred(value: boolean) {
		isBrowserPreferredByUser.value = value;
	}

	function initOfflineEvents() {
		window.addEventListener("offline", () => {
			setIsClientOffline(true);
		});

		window.addEventListener("online", () => {
			setIsClientOffline(false);
		});
	}

	function initPwaEvents() {
		if (isBrowser.value) {
			const { trackTrace, trackEvent } = useObservability();

			window.addEventListener("beforeinstallprompt", (event) => {
				event.preventDefault();
				deferredPrompt.value = event;
				trackTrace({
					message: "Prompt: 'beforeinstallprompt' event was fired.",
					severityLevel: 1
				});
			});

			window.addEventListener("appinstalled", () => {
				trackTrace({
					message: "Prompt: PWA was installed.",
					severityLevel: 1
				});
				trackEvent(AppInsightsEvent.PwaInstalled);
				deferredPrompt.value = null;
			});

			window.matchMedia("(display-mode: standalone)").addEventListener("change", (evt) => {
				if (evt.matches && router.currentRoute.value.name === "install")
					location.reload();
			});
		}
	}

	return {
		deferredPrompt,
		isClientOffline,
		isCloudOffline,
		isBrowser,
		isBrowserPreferred,
		isStandalone,
		isSafariDesktop,
		isSafariMobile,
		initPwaEvents,
		initOfflineEvents,
		setIsCloudOffline,
		setIsBrowserPreferred
	};
});
