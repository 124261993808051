import { useAppStore, useEndpointsStore } from "@qamf/shell-app-sdk";
import { createRestClient } from "scarlett";

export default function defineLocalBackend() {
	const baseClient = createRestClient({
		host: "",
		basePath: "",
		throw: true,
		credentials: "omit",
		mode: "cors",
		headers: new Headers({
			"Content-Type": "application/json",
			"X-ApiKey": "Tv9741AdiQTwospK5ki0X4NY4x6eV8xvaA"
		}),
		responseType: (_, res) => {
			if (res?.status === 500)
				return "text";

			return "json";
		}
	})();

	const { localBackend } = useEndpointsStore();
	const host = __BACKEND_LOCAL_URL__ || localBackend.value;

	const { systemId } = useAppStore();
	baseClient.setOption("host", host);
	baseClient.setOption("basePath", `/api/QDesk/${systemId.value}`);

	return () => baseClient;
}
