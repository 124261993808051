import { useObservability } from "@qamf/shell-app-sdk";
import { useRegisterSW } from "virtual:pwa-register/vue";

const registerServiceWorker = () => {
	useRegisterSW({
		immediate: true, // Automatic PAGE reload
		onOfflineReady() {
			// show a ready to work offline message to the user with an OK button.
			// When the user clicks the OK button, just hide the prompt.
			const { trackTrace } = useObservability();
			trackTrace({
				message: "Service worker is offline",
				severityLevel: 1
			});
		},
		onNeedRefresh() {
			// show a prompt to the user with refresh and cancel buttons
			const { trackTrace } = useObservability();
			trackTrace({
				message: "Service worker need refresh",
				severityLevel: 1
			});
		},
		onRegisteredSW(swUrl, r) {
			// check if there is a new version of your application available
			const { trackTrace } = useObservability();
			trackTrace({
				message: "Service worker registered",
				severityLevel: 1
			});
		}
	});
};

export default registerServiceWorker;
