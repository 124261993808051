import { useTranslations } from "@app-i18n/useTranslations";
import { defineComponent } from "vue";

export default defineComponent({
	name: "AccessDenied",
	components: {},
	setup() {
		const { translateKey } = useTranslations();

		return {
			translateKey
		};
	}
});
