import {
	createTranslations,
	useAppStoreManager
} from "@qamf/shell-app-sdk";
import type { ITranslation } from "@qamf/xliff-loader";
import { useNavigatorLanguage } from "@vueuse/core";

import Languages from "./languages.json" assert { type: "json" };
type UseTranslations = ReturnType<typeof createTranslations>;
let _useTranslations: UseTranslations = () => {
	throw new Error("useTranslations not initialized");
};

export async function initMultiLanguage() {
	const useTranslations = createTranslations();
	const { setup: setupI18n, setLanguage } = useTranslations();

	setupI18n<ITranslation>({
		languages: Languages,
		languageFetcher(langCode) {
			console.log(`[i18n] Setting lang code: ${langCode}`);
			switch (langCode) {
				case "de-DE":
					return import("@qamf/qst-qdesk/de-DE/qdesk.xlf");
				case "fr-FR":
					return import("@qamf/qst-qdesk/fr-FR/qdesk.xlf");
				case "it-IT":
					return import("@qamf/qst-qdesk/it-IT/qdesk.xlf");
				case "es-ES":
					return import("@qamf/qst-qdesk/es-ES/qdesk.xlf");
				case "nb-NO":
					return import("@qamf/qst-qdesk/nb-NO/qdesk.xlf");
				case "sv-SE":
					return import("@qamf/qst-qdesk/sv-SE/qdesk.xlf");
				case "da-DK":
					return import("@qamf/qst-qdesk/da-DK/qdesk.xlf");
				case "id-ID":
					return import("@qamf/qst-qdesk/id-ID/qdesk.xlf");
				case "nl-NL":
					return import("@qamf/qst-qdesk/nl-NL/qdesk.xlf");
				case "ja-JP":
					return import("@qamf/qst-qdesk/ja-JP/qdesk.xlf");
				default:
					return import("./locale.xlf");
			}
		},
		translateKeyResolver(repo, key) {
			if (!repo) {
				console.warn(
					`[i18n] The Translation-Repository is not defined. Returning the key as is: ${key}`
				);
				return key;
			}
			let translateString = "";

			if (key in repo.target && repo.target[key])
				translateString = repo.target[key].trim();
			else if (key in repo.source && repo.source[key])
				translateString = repo.source[key].trim();
			else {
				console.warn(
					`[i18n] The Translation-Key '${key}' was not translated`
				);
				translateString = `[${key.toUpperCase()}]`;
			}
			if (!translateString) {
				console.warn(
					`[i18n] The Translation-Key '${key}' is empty.`
				);
			}
			return translateString;
		}
	});

	const { setLangIsoCode } = useAppStoreManager();
	const langToSet = useNavigatorLanguage().language.value ?? "en-US";
	setLangIsoCode(langToSet);
	await setLanguage(langToSet);
	_useTranslations = useTranslations;
}
export const useTranslations = () => _useTranslations();
