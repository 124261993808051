import { QOverlay } from "@qamf/lighthouse";
import { useAuthentication } from "@qamf/shell-app-sdk";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
	name: "LogoutCallback",
	components: {
		QOverlay
	},
	setup() {
		const { login, logoutProcessCallback } = useAuthentication();
		const isViewLoading = ref(false);

		onMounted(async() => {
			isViewLoading.value = true;

			const processLogout = await logoutProcessCallback();
			if (processLogout)
				login();
			else
				throw processLogout;
		});

		return {
			isViewLoading
		};
	}
});
