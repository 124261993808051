import usePersistentStorage from "@app-utilities/persistent-storage";
import { createRestClient } from "scarlett";

export default function define4DAgent() {
	// FIXME: Temporary agent4D to be moved to module-connector
	// Tech Story 59578: [QDesk] Move agent4D rest to module-connector
	const baseClient = createRestClient({
		host: "",
		basePath: "",
		throw: true,
		credentials: "omit",
		mode: "cors",
		headers: new Headers({
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "*"
		}),
		responseType: (_, res) => {
			if (res?.status === 500)
				return "text";

			return "json";
		}
	})();

	const storageTerminalAuthInfo = usePersistentStorage().getItem("terminalAuthInfo");
	const agent4DUrl = storageTerminalAuthInfo?.LanIpAddress ?? null;
	const host = __AGENT_4D_URL__ || agent4DUrl || "";

	baseClient.setOption("host", host);

	return () => baseClient;
}
