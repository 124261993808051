import usePersistentStorage from "@app-utilities/persistent-storage";
import type { LocalFunctionalities, PortalFunctionalities } from "@app-utilities/security";
import { useCloudBackend, useLocalBackend } from "@qamf/shell-app-sdk";
import type { IResponse } from "scarlett";

import type { ICenterInfo, IDeviceConfiguration, IDeviceToken, IResponseError, IResponseErrorToken, NeoverseConfiguration } from "./configurations.interfaces";

const ErrorPairingConfiguration = ["authorization_pending", "expired_token"] as const;
type ConfigurationErrorCodes = typeof ErrorPairingConfiguration[number];

type RestClient = {
	getDeviceCode(): Promise<IResponse<IDeviceConfiguration, IResponseErrorToken<string>>>,
	verifyToken(deviceCode: string): Promise<IResponse<IDeviceToken, IResponseErrorToken<ConfigurationErrorCodes>>>,
	getCenterInfo(systemId: number, terminalNumber: number): Promise<IResponse<ICenterInfo, IResponseError<string>>>,
	getFunctionalities(systemId: number): Promise<IResponse<PortalFunctionalities, IResponseError<string>>>,
	getFunctionalitiesLocal(): Promise<IResponse<LocalFunctionalities, IResponseError<string>>>,
	getNeoverseCloudConfiguration(systemId: number): Promise<IResponse<NeoverseConfiguration, IResponseError<ConfigurationErrorCodes>>>,
}

export const useAppRestClient = (): RestClient => {
	function getDeviceCode() {
		const { post, getOption } = useCloudBackend();

		const headers = getOption("headers");
		headers.set("Content-Type", "application/x-www-form-urlencoded");

		const bodyParams = new URLSearchParams();
		bodyParams.append("client_id", "qdesk");
		bodyParams.append("scope", "login");

		return post("/IdentityProvider/device", {
			headers,
			basePath: "/",
			body: bodyParams.toString()
		});
	}

	function verifyToken(deviceCode: string) {
		const { post, getOption } = useCloudBackend();

		const headers = getOption("headers");
		headers.set("Content-Type", "application/x-www-form-urlencoded");

		const bodyParams = new URLSearchParams();
		bodyParams.append("client_id", "qdesk");
		bodyParams.append("grant_type", "urn:ietf:params:oauth:grant-type:device_code");
		bodyParams.append("device_code", deviceCode);

		return post("/IdentityProvider/token", {
			headers,
			basePath: "/",
			body: bodyParams.toString(),
			throwExcluding: [
				error => {
					const statusCode = error?.statusCode;
					const code = error?.data?.error;
					const authPending = statusCode === 400 && code === "authorization_pending";
					const expiredToken = statusCode === 400 && code === "expired_token";
					return authPending || expiredToken;
				}
			]
		});
	}

	async function getCenterInfo(systemId: number, terminalNumber: number) {
		const { get, getOption } = useCloudBackend();
		const { getItem } = usePersistentStorage();

		const terminalToken = getItem("terminalToken");
		if (!terminalToken) throw new Error("Failed get center info: missing terminalToken.");

		// The following header is used only in this API call and cannot be centralized
		const terminalAccessToken = terminalToken.access_token;
		const headers = getOption("headers");
		headers.set("Authorization", `Bearer ${terminalAccessToken}`);

		return get(`${systemId}/access/${terminalNumber}`, {
			headers,
			throwExcluding: [
				error => {
					const statusCode = error?.statusCode;
					const code = error?.data?.Error.Code;
					const invalidAuthorization = statusCode === 401 && code === "InvalidToken";
					return invalidAuthorization;
				}
			]
		});
	}

	async function getFunctionalities(systemId: number) {
		const { get } = useCloudBackend();

		return get<PortalFunctionalities, IResponseError<string>>(`${systemId}/functionalities`, {
			throwExcluding: [
				error => {
					const statusCode = error?.statusCode;
					const code = error?.data?.Error.Code;
					const invalidAuthorization = statusCode === 401 && code === "Unauthorized";
					return invalidAuthorization;
				}
			]
		});
	}

	async function getFunctionalitiesLocal() {
		const { get, setOption } = useLocalBackend();
		setOption("headers",
			new Headers({
				"Content-Type": "application/json",
				"X-ApiKey": "Tv9741AdiQTwospK5ki0X4NY4x6eV8xvaA",
				"X-Identity-Username": "Test_NewLaneOptions"
			})
		);
		return get<LocalFunctionalities, IResponseError<string>>("/Functionalities");
	}

	async function getNeoverseCloudConfiguration(systemId: number) {
		const { get } = useCloudBackend();

		return get(`/Neoverse/${systemId}/Access`, {
			throwExcluding: [
				error => {
					const statusCode = error?.statusCode;
					const code = error?.data?.Error.Code;
					const invalidModelState = statusCode === 400 && code === "InvalidModelState";
					const missingLanIpAddress = statusCode === 409 && code === "MissingLanIpAddress";
					const unknownDeviceCode = statusCode === 409 && code === "UnknownDeviceCode";
					return invalidModelState || missingLanIpAddress || unknownDeviceCode;
				}
			]
		});
	}

	return {
		getDeviceCode,
		verifyToken,
		getCenterInfo,
		getFunctionalities,
		getFunctionalitiesLocal,
		getNeoverseCloudConfiguration
	};
};
